import * as React from "react";
import { Layout } from "@components/Layout";
import { graphql } from "gatsby";
import { Container } from "@components/Layout/Container";
import { DynamicComponent } from "@components/DynamicComponent";
import { TOCPageProps } from "./guide-overview";
import { isLive, isPublic } from "@/utils";
import { Maybe, SEO } from "@/types/graphql";
import { not } from "fp-ts/lib/function";
import solveAProblemIcon from "@/icons/get-help-page/solve-problem-icon.svg";
import toolkitIcon from "@/icons/get-help-page/toolkit-white.svg";
import gettingStartedIcon from "@/icons/get-help-page/get-started-icon.svg";
import { Internal } from "@components/Link";
import { RenderMultiple } from "@components/RenderMultiple";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { heroAtom, seoAtom } from "@/store";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { SubTitle } from "@components/Header/SubTitle";
import { RenderSectionLinks } from "@components/RenderSectionLinks";
import { useHydrateAtoms } from "jotai/utils";
import { type Icon } from "@fortawesome/fontawesome-svg-core";
import { EmailListForm } from "../components/EmailListForm";
import { Helmet } from "react-helmet";

type HomepageLinkProps = {
  icon: { src: string; alt: string };
  questionText: string;
  link: { label: string; href: string };
};

const HomepageLink = ({ icon, questionText, link }: HomepageLinkProps) => {
  return (
    <div className="w-full bg-primary mt-3 group/homepage-link">
      <Internal
        to={link.href}
        className="w-full flex flex-col md:flex-row justify-between items-center px-5 py-3 text-white bg-purple hover:text-underline"
      >
        <div className="flex items-center w-full md:w-2/3">
          <img src={icon.src} alt={icon.alt} className="h-20 w-20" />
          <p className="text-gray-100 pl-10 font-light">{questionText}</p>
        </div>
        <div className="flex items-end self-stretch justify-end">
          <p className="homepage-link-text text-gray-100 flex items-center group-hover/homepage-link:underline">
            {link.label}
            <FontAwesomeIcon
              icon={faCaretRight as Icon}
              className="text-2xl ml-2"
            />
          </p>
        </div>
      </Internal>
    </div>
  );
};

type IndexPageProps = {
  data: Maybe<
    Omit<TOCPageProps["data"], "sanityVisualToc"> & {
      hero: Maybe<any>;
      sanityPage: Maybe<{
        includeEmailForm: Maybe<boolean>;
        _rawBody: any;
        hero: any;
        seo: SEO;
      }>;
      sanityVisualToc: Maybe<{
        effectivePractices: Maybe<
          {
            seo: SEO;
            publicationStatus: Maybe<string>;
            heading: Maybe<string>;
            category: Maybe<string>;
            slug: Maybe<{ current: Maybe<string> }>;
          }[]
        >;
      }>;
    }
  >;
};

const IndexPage = ({ data }: IndexPageProps) => {
  const effectivePractices = data?.sanityVisualToc?.effectivePractices || [];
  const liveLinks = effectivePractices.filter(isLive).filter((page) => {
    // Exceptions
    return !["Implementing Research-Based Instructional Practices"].includes(
      page.heading || "",
    );
  });
  const publicDraftLinks = effectivePractices.filter((x) =>
    isPublic(x) && not(isLive)(x)
  );

  const comingSoonLinks = effectivePractices.filter(not(isPublic));

  const sectionsClassName = "mt-8";

  const homepageLinks: HomepageLinkProps[] = [
    {
      icon: { src: gettingStartedIcon, alt: "" },
      questionText: "Want to learn how to most effectively use this guide?",
      link: { label: "Get Started", href: "/get-started" },
    },
    {
      icon: { src: solveAProblemIcon, alt: "" },
      questionText: "Why did you come here? Let's figure out your goal.",
      link: { label: "Map Your Program’s Goals", href: "/goal-map" },
    },
    {
      icon: { src: toolkitIcon, alt: "" },
      questionText: "Looking for the APS Toolkit for Departments Under Threat?",
      link: { label: "Department Toolkit", href: "/toolkit" },
    },
  ];

  useHydrateAtoms([[heroAtom, data?.sanityPage?.hero ?? data?.hero], [
    seoAtom,
    data?.sanityPage?.seo,
  ]]);

  return (
    <Layout>
      <Helmet>
        <title>EP3 Guide</title>
      </Helmet>
      <Container>
        <Header>
          <Title>Effective Practices for Physics Programs (EP3)</Title>
          <SubTitle>
            Supporting physics programs with collections of knowledge,
            experience, and proven good practice for responding to challenges
            and engaging in systematic improvement.
          </SubTitle>
        </Header>
        <div className="flex flex-col lg:flex-row w-full pt-4">
          <div className="w-full lg:w-1/2 pr-10 landing-page-dynamic">
            <DynamicComponent blocks={data?.sanityPage?._rawBody || []} />
          </div>
          <div className="w-full lg:w-1/2">
            <RenderMultiple of={HomepageLink} fromPropsList={homepageLinks} />
          </div>
        </div>

        <RenderSectionLinks
          title="Effective Practices Guide Sections"
          description="Developed and reviewed by experts in the physics community, these sections have been approved by the task force. They address a wide range of topics relevant to ensuring a thriving physics program, including actionable practices and implementation strategies."
          sections={liveLinks}
          className={sectionsClassName}
        />
        <RenderSectionLinks
          title="Effective Practices Draft Sections"
          description="These sections are still undergoing a rigorous peer-review process but are available for viewing in draft form."
          sections={publicDraftLinks}
          className={sectionsClassName}
        />
        <RenderSectionLinks
          title="Effective Practices Sections Under Development"
          description="Coming soon, these sections will be released incrementally in the coming months."
          sections={comingSoonLinks}
          className={sectionsClassName}
        />
      </Container>
      {data?.sanityPage?.includeEmailForm && (
        <div className="grid items-center justify-center w-full p-5 mt-4">
          <EmailListForm />
        </div>
      )}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero-home-page.jpg" }) {
      ...heroImageFragment
    }

    sanityPage(slug: { current: { eq: "/" } }) {
      includeEmailForm

      seo {
        title
        description
      }

      hero {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
        _rawAsset(resolveReferences: { maxDepth: 10 })
        _rawCrop(resolveReferences: { maxDepth: 10 })
        _rawHotspot(resolveReferences: { maxDepth: 10 })
      }

      _rawBody(resolveReferences: { maxDepth: 5 })
    }

    sanityVisualToc {
      effectivePractices {
        __typename
        slug {
          current
        }
        publicationStatus
        heading
        category
      }
    }
  }
`;
