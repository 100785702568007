import { Heading } from "@components/Heading";
import * as React from "react";
import { Maybe, Section, SEO } from "../../types/graphql";
import { getLabel } from "../../utils";
import { RenderMultiple } from "../RenderMultiple";
import { SectionLink } from "./SectionLink";

export type PartialSection = Pick<Section, "heading" | "publicationStatus"> & {
  seo: Maybe<SEO>;
  slug: Maybe<{ current: Maybe<string> }>;
};

export const SectionItem = ({ slug, ...section }: PartialSection): JSX.Element | null => {
  const label = getLabel(section);
  if (slug?.current == null || label == null) {
    return null;
  }
  return (
    <li key={label}>
      <SectionLink to={slug?.current} publicationStatus={section.publicationStatus}>
        {label}
      </SectionLink>
    </li>
  );
};

export type RenderSectionLinksProps = {
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  sections: PartialSection[];
  description?: string;
};

export const RenderSectionLinks = ({
  title = "Available Sections",
  className = "",
  style = {},
  sections,
  description,
}: RenderSectionLinksProps) => {
  if (!sections.length) {
    return null;
  }
  return (
    <div className={`w-full ${className}`} style={style}>
      <Heading level={2} className="m-0 text-black">
        {title}
      </Heading>
      {description && <p>{description}</p>}
      <nav className="mt-4">
        <ul
          className="grid grid-cols-1 row-auto gap-2 md:grid-cols-3 lg:grid-cols-4 border-box"
          style={{ listStyleType: "none", margin: 0, flex: 1 }}
        >
          <RenderMultiple of={SectionItem} fromPropsList={sections} />
        </ul>
      </nav>
    </div>
  );
};
