import * as React from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { isComingSoon } from "../../utils";
import { Section } from "../../types/graphql";

type SectionLinkProps = GatsbyLinkProps<unknown> & Pick<Section, "publicationStatus">;

export const SectionLink: React.FC<SectionLinkProps> = ({ to, children, publicationStatus, className = "" }) => {
  const bgColor = isComingSoon({ publicationStatus }) ? "bg-gray-500" : "bg-secondary";
  const classNames = [
    "flex-1 h-full  py-4 px-4 text-lg text-white flex justify-center items-center text-center",
    className,
    bgColor,
  ].join(" ");

  return (
    <GatsbyLink className={classNames} to={to}>
      {children}
    </GatsbyLink>
  );
};
